import { createContext } from 'react';

interface NotificationContextProps {
  message: string;
  type: 'success' | 'error' | 'info' | 'warning';
  showNotification: boolean;
  setNotification: (notification: { message: string, duration?: number, messageType: 'success' | 'error' | 'info' | 'warning' }) => void;
  hideNotification: () => void;
}
const MessageContext = createContext<NotificationContextProps | undefined>(undefined);

export default MessageContext;