import { ReactNode } from 'react';

interface ButtonProps {
    children: ReactNode;
    className?: string;
    disabled?: boolean;
    onClick?: () => void;
    id?: string;
    type: 'button' | 'submit' | 'reset';
}

const Button = ({ children, className, disabled, type, onClick, id }: ButtonProps) => {
    const conditionalButtonProps = {
        ...onClick && { onClick },
        ...disabled && { disabled },
        ...id && { id }
    }
    return (
        <button type={type} {...conditionalButtonProps} className={`button ${className ? className : ''}`}>
            {children}
        </button>
    );
}

export default Button;