import { Dialog, DialogPanel } from "@headlessui/react";
import Tabs from "./Tabs";

interface DialogInfoProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const DialogInfo = ({ open, setOpen }: DialogInfoProps) => {
    return (
        <Dialog open={open} onClose={setOpen} className="relative z-10 prose dark:prose-dark">
            <div className="fixed inset-0 bg-black bg-opacity-30" />
            <div className="fixed inset-0 overflow-hidden flex justify-end">
                <DialogPanel
                    className="pointer-events-auto w-full max-w-md transform transition-all duration-500 ease-in-out sm:duration-700"
                >
                    <div className="flex h-full flex-col bg-secondary-100 dark:bg-secondary-500 shadow-xl">
                        {/* Close Button */}
                        <div className="flex justify-end p-4">
                            <button
                                type="button"
                                onClick={() => setOpen(false)}
                                className="rounded-md focus:outline-none"
                            >
                                <span className="sr-only">Close panel</span>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="h-6 w-6">
                                    <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                                </svg>
                            </button>
                        </div>

                        {/* Tabs and Content */}
                        <div className="flex flex-col flex-grow h-full">
                            <Tabs />
                        </div>
                    </div>
                </DialogPanel>
            </div>
        </Dialog>
    );
};

export default DialogInfo;