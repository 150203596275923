import { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";

const Layout = ({ children }: { children: React.ReactNode }) => {

    const [theme, setTheme] = useState<'light' | 'dark'>('light');

    // Load theme from local storage on page load
    useEffect(() => {
        const savedTheme = localStorage && localStorage.getItem('theme');
        if (savedTheme) {
            setTheme(savedTheme as 'light' | 'dark');
            document.documentElement.classList.toggle('dark', savedTheme === 'dark');
        }
    }, []);

    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
        document.documentElement.classList.toggle('dark', newTheme === 'dark');
        localStorage.setItem('theme', newTheme); // Save theme to local storage
    };

    return (
        <main className={`fixed inset-0 w-full overflow-hidden flex flex-col ${theme === 'dark' ? 'dark' : ''}`}>
            <Header theme={theme} toggleTheme={toggleTheme} />
            {children}
            <Footer />
        </main>
    );
}

export default Layout;