import { ReactNode } from 'react';

interface LinkProps {
    children: ReactNode;
    className?: string;
    disabled?: boolean;
    href: string;
    target?: string;
}

const Link = ({ children, className, disabled, href, target }: LinkProps) => {
    const conditionalLinkProps = {
        ...disabled && { disabled },
        ...target && { target },
    }
    return (
        <a {...conditionalLinkProps} href={href} className={`button inline-flex items-center justify-center ${className ? className : ''}`}>
            {children}
        </a>
    );
}

export default Link;