import { ReactNode, MouseEventHandler } from 'react';

interface ButtonRoundProps {
    children: ReactNode;
    className?: string;
    onClick: MouseEventHandler<HTMLButtonElement>;
    id?: string;
}

const ButtonRound = ({ children, className, onClick, ...props }: ButtonRoundProps) => (
    <button onClick={onClick} className={`button-round ${className}`} {...props}>
        {children}
    </button>
);

export default ButtonRound;