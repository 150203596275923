import { ReactNode, useState } from "react";
import MessageContext from "./MessageContext";

const MessageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [message, setMessage] = useState<string>('');
    const [type, setType] = useState<'success' | 'error' | 'info' | 'warning'>('info');
    const [showNotification, setShowNotification] = useState<boolean>(false);

    const hideNotification = () => {
        setShowNotification(false);
    };

    const setNotification = (notification: { message: string, duration?: number, messageType: 'success' | 'error' | 'info' | 'warning' }) => {
        const { duration = 5000, message, messageType } = notification;
        setMessage(message);
        setType(messageType);
        setShowNotification(true);
        const timer = setTimeout(() => {
            setShowNotification(false);
        }, duration)

        return () => clearTimeout(timer);
    };

    return (
        <MessageContext.Provider value={{ message, type, showNotification, setNotification, hideNotification }}>
            {children}
        </MessageContext.Provider>
    );
};

export default MessageProvider;