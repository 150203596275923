import { lazy, Suspense, useCallback, useEffect, useState } from 'react'
import DOMPurify from 'dompurify'
import { useForm } from 'react-hook-form';
import { Transition } from '@headlessui/react';
import './App.css'
import Section from './Section';
import Button from './Button';
import useMessage from './Context/useMessage';
const Notification = lazy(() => import('./Notification'));
const NotificationSection = lazy(() => import('./NotificationSection'));

interface WorkerResponse {
  formatted: string;
  latexMatches: Array<{ formula: string, index: number }>;
}

function App() {
  const [formattedContent, setFormattedContent] = useState<string>(''); // Formatted content from worker
  const [isPreviewVisible, setIsPreviewVisible] = useState<boolean>(false); // State to show/hide preview
  const [clipboardAccess, setClipboardAccess] = useState<boolean>(false);
  const [clipboardAccessChecked, setClipboardAccessChecked] = useState<boolean>(false);
  const { message, type, setNotification, hideNotification, showNotification } = useMessage();
  const { register, handleSubmit, reset, formState: { isDirty } } = useForm({
    defaultValues: { content: '' }
  });

  const checkToSeeIfClipboardIsAvailable = useCallback(async () => {
    try {
      if (!navigator.clipboard || !window.ClipboardItem) {
        setClipboardAccess(false);
        setClipboardAccessChecked(true);
        setNotification({
          message: 'Clipboard access not available',
          messageType: 'error',
        });
        throw new Error("Clipboard access is not available in checkToSeeIfClipboardIsAvailable");
      }

      const readAccess = await navigator.permissions?.query({ name: "clipboard-read" as PermissionName });
      const writeAccess = await navigator.permissions?.query({ name: "clipboard-write" as PermissionName });

      if (readAccess?.state === 'denied') {
        setClipboardAccess(false);
        setClipboardAccessChecked(true);
        setNotification({
          message: 'Clipboard read access denied',
          messageType: 'error',
        });
        throw new Error("Clipboard read access denied in checkToSeeIfClipboardIsAvailable");
      }

      if (writeAccess?.state === 'denied') {
        setClipboardAccess(false);
        setClipboardAccessChecked(true);
        setNotification({
          message: 'Clipboard write access denied',
          messageType: 'error',
        });
        throw new Error("Clipboard write access denied in checkToSeeIfClipboardIsAvailable");
      }

      setClipboardAccessChecked(true);
      setClipboardAccess(true);
      return;
    } catch (error) {
      console.error('Error in checkToSeeIfClipboardIsAvailable:', error);
    }

  }, [setNotification]);

  const isIOS = /iPad|iPhone|iPod/.test(navigator.platform)
    || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

  useEffect(() => {
    if (!clipboardAccessChecked && isIOS === false) {
      checkToSeeIfClipboardIsAvailable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Reset the preview visibility when the content changes after submission
  useEffect(() => {
    if (isDirty) {
      setIsPreviewVisible(false); // Disable preview visibility when content changes
    }
  }, [isDirty]);

  const onSubmit = (data: { content: string }) => {
    handlePreview(data.content);
    reset(data, { keepValues: true });
  }

  const handleCopy = async () => {
    if (clipboardAccess === true && formattedContent !== '') {
      const blob = new Blob([formattedContent], { type: 'text/html' });
      const data = [new ClipboardItem({ 'text/html': blob })];
      await navigator.clipboard.write(data);
      setNotification({
        message: 'Copied to clipboard',
        messageType: 'success',
      });
    } else {
      setNotification({
        message: 'Clipboard access not available',
        messageType: 'error',
      });
      console.error('Clipboard access is not available in handleCopy');
    }
  }
  // preview and copy button should be disabled to start
  // when user pastes text into content, enable the preview button
  // when user hits preview, enable the copy button, disable the preview button
  // when user hits copy, leave it enabled until they change the content
  // then if they have changed the content, disable the copy button
  // if they hit preview again, enable the copy button

  const handlePreview = (content: string) => {
    const sanitizedContent = DOMPurify.sanitize(content);
    // step 1: use markdown worker and detect latex blocks using regex
    const MarkdownWorker = new Worker(new URL('./markdown.worker.ts', import.meta.url), {
      type: 'module'
    });
    MarkdownWorker.postMessage({ content: sanitizedContent });

    MarkdownWorker.onmessage = (e: MessageEvent<WorkerResponse>) => {
      const { formatted, latexMatches } = e.data;
      let updatedContent = formatted;


      // Step 2: Check if there are any LaTeX matches
      if (latexMatches.length > 0) {

        latexMatches.forEach(({ formula, index }) => {
          const LatexWorker = new Worker(new URL('./latex.worker.ts', import.meta.url), {
            type: 'module'
          });
          LatexWorker.postMessage({ formula, index });

          LatexWorker.onmessage = (e: MessageEvent<{ svg: string, index: number }>) => {
            const { svg, index } = e.data;
            const uniqueId = `formula-${index}`;
            updatedContent = updatedContent.replace(`<latex-placeholder id="${index}"></latex-placeholder>`, svg);

            // Step 2: Convert SVG to PNG using the canvas API
            const img = new Image();
            img.src = 'data:image/svg+xml;base64,' + btoa(svg);
            img.onload = () => {
              const scaleFactor = 3;
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              if (!ctx) return;

              canvas.width = img.width * scaleFactor;
              canvas.height = img.height * scaleFactor;
              ctx.scale(scaleFactor, scaleFactor);
              ctx.drawImage(img, 0, 0, img.width, img.height);

              // Convert canvas to PNG data URL
              const pngDataUrl = canvas.toDataURL('image/png');
              const wrappedTag = `<img class="dark:invert" id="${uniqueId}" src="${pngDataUrl}" alt="LaTeX formula" style="width:${img.width}px; height:${img.height}px;" />`;
              updatedContent = updatedContent.replace(svg, wrappedTag);
              setFormattedContent(updatedContent);
              setIsPreviewVisible(true);
            };

            img.onerror = (e) => {
              setNotification({
                message: 'Error processing the fraction or equation',
                messageType: 'error',
              });
              console.error('Error loading image:', e);
            }
            setFormattedContent(updatedContent);
            setIsPreviewVisible(true);
            LatexWorker.terminate(); // Terminate after completion
          }
        });
      } else {
        setFormattedContent(updatedContent);
        setIsPreviewVisible(true);
      }
      MarkdownWorker.terminate(); // Terminate after completion
    };

    MarkdownWorker.onerror = (e: Event) => {
      setNotification({
        message: 'Error processing the text',
        messageType: 'error',
      });
      console.error('Worker error in handlePreview:', e);
    }
  }

  return (
    <>
      <div className='mx-auto container max-w-7xl grid-cols-1 lg:grid-cols-3 px-4 w-full'>
        <form onSubmit={handleSubmit(onSubmit)} className='grid grid-cols-1 gap-8 md:grid-cols-12'>
          <div className="col-span-12 md:col-span-6">
            <Section className='input-border' header={<><div className="flex justify-between items-center">
              <label htmlFor="content" className='label sr-only'>
                ChatGPT Output
              </label>
            </div>
              <Button type='submit' id='tidy' disabled={!isDirty || isPreviewVisible}>Tidy</Button>
            </>}>
              <textarea
                {...register("content", { required: true })}
                id='content'
                spellCheck="false"
                className="input input-output resize-none prose dark:prose-dark" // Constrain height
                placeholder="Paste your ChatGPT output here..."
              />
            </Section>

          </div>

          <div className="col-span-12 md:col-span-6">
            <Section header={
              <>
                <div>
                  <div className="label sr-only">
                    Google Docs Input
                  </div>
                </div>
                <Button type='button' id='copy' onClick={handleCopy} disabled={!isPreviewVisible}>Copy</Button>
              </>
            } className='output-border'>
              <div className='input-output output prose dark:prose-dark'>
                <Transition show={isPreviewVisible} as='div'>
                  <div className='transition data-[closed]:data-[enter]:translate-y-2 data-[enter]:transform data-[closed]:opacity-0 data-[enter]:duration-800 data-[leave]:duration-500 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0' dangerouslySetInnerHTML={{ __html: formattedContent }} />
                </Transition>
              </div>
            </Section>
          </div>
        </form >
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <NotificationSection>
          <Notification showNotification={showNotification} dismissible={true} handleClose={() => hideNotification()} message={message} type={type} />
        </NotificationSection>
      </Suspense>
    </>
  )
}

export default App
