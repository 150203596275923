import { Tab, TabGroup, TabList, TabPanel, Transition } from '@headlessui/react';
import { useState } from 'react';
import Icons from './Icons'
import Link from './Link';

const Tabs = () => {
    const [tabIndex, setTabIndex] = useState(0)
    const tabContent = [

        {
            label: 'How to use',
            content: (
                <div className="px-8 py-4">
                    <ol className='pe-[1.625em]'>
                        <li>Go to ChatGPT and use the copy button.</li>
                        <img src='chatgpt-copy-button.gif' alt='Copy button' />
                        <li>Go to TidyText.cc and paste in the text input. Press the Tidy button.</li>
                        <img src='tidy.gif' alt='Tidy button' />
                        <li>Click the copy button in the top right side of the output and paste it in your doc.</li>
                    </ol>
                    <p>Sit back, and enjoy your TidyText!</p>
                </div>
            ),
        },
        {
            label: 'About',
            content: (
                <div className="px-8 py-4 space-y-6">
                    <h1 className='mt-8 font-display text-2xl font-bold tracking-tight text-black dark:text-white'>TidyText helps format text copied from ChatGPT or other sources for easy use in Google Docs.</h1>
                    <p>
                        TidyText was born out of the need to take the hassle out of copying ChatGPT outputs into Google Docs, ensuring that the text is well-formatted and ready to go, without having to manually clean it up. This tool was designed to save time, especially for users who rely on AI-generated text to improve their writing or create content quickly.
                    </p>
                    <div className='bg-secondary-100/40 dark:bg-black/20 text-base px-4 pt-1 pb-6 text-center rounded-xl'>
                        <p>I welcome any questions or feedback!</p>
                        <Link href='https://docs.google.com/forms/d/e/1FAIpQLScseXdhnPilU4sswT7kx8mWiLXRyfQwam2gh1uyToLWOBuT4A/viewform?usp=sf_link' target='_blank'>Submit feedback here <Icons.NewTab className='ml-1 -mr-0.5 h-4 w-4' /></Link>
                    </div>
                    <p>Key Features</p>
                    <ul>
                        <li>Handles Complex Formatting:</li>
                        <ul>
                            <li>
                                Headings: Ensures proper formatting for headings, keeping your document structure intact.
                            </li>
                            <li>
                                Bullet Points and Lists: Cleans up bullet points and numbered lists, making them easier to read.
                            </li>
                            <li>
                                Tables: Automatically reformats tables so they look cleaner and more professional.
                            </li>
                            <li>
                                Fractions: Now supports rendering fractions—perfect for math-related content.
                            </li>
                            <li>
                                Math Equations: Tidies up math equations for easy inclusion in documents.
                            </li>
                        </ul>
                        <li>
                            One-Click Google Docs Integration (Coming Soon): A time-saving feature that’ll let you export formatted content straight into Google Docs with just one click.
                        </li>
                        <li>
                            Light Mode & Dark Mode: Available in both light and dark modes, so you can work comfortably in any environment.
                        </li>
                        <li>
                            No Sign-Up Required: Completely free to use—no sign-up, no personal info needed. Even with the Google Docs integration, we don’t store your email—it’s only used to create a new doc in your Google Drive, and that’s it!
                        </li>
                        <li>
                            Customizable Copy-Paste: Keeps your formatting intact when copying to Google Docs or Word—no messy HTML tags or broken layouts to deal with.
                        </li>
                    </ul>
                </div >
            ),
        }
    ]

    return (
        <TabGroup selectedIndex={tabIndex} onChange={setTabIndex} className='flex flex-col h-full'>
            {/* Tab List */}
            <TabList className="flex space-2">
                {tabContent.map((tab, index) => (
                    <Tab
                        key={index}
                        className={({ selected }) =>
                            `w-full py-2.5 text-sm font-medium leading-5 text-black dark:text-white rounded-t-xl focus:outline-none transition-all duration-300 ease-in-out transform ${selected
                                ? 'bg-white dark:bg-blue-700'
                                : 'hover:bg-white/[0.12] dark:hover:bg-bg-blue-700/[0.12]'
                            }`
                        }
                    >
                        {tab.label}
                    </Tab>
                ))}
            </TabList>

            {/* Tab Panels */}
            <div className="relative flex-grow grid grid-cols-1 grid-rows-1 bg-white dark:bg-blue-700 rounded-b-xl overflow-y-scroll">
                {tabContent.map((tab, index) => (
                    <TabPanel key={index} className={`absolute inset-0 col-start-1 row-start-1 transition-all duration-300 ease-in-out ${tabIndex === index ? 'block' : 'hidden'
                        }`}>
                        <Transition
                            appear
                            show
                            enter="transition-opacity duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="transition-opacity duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            {tab.content}
                        </Transition>
                    </TabPanel>
                ))}
            </div>
        </TabGroup>
    );
}

export default Tabs